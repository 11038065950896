body {
  background-color: #fbfdf9;
  color: #1a1c18;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}


h5 {
  font-size: 12px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1px;
  color: rgba(0,0,0,0.6);
  padding: 2px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
input[type="time"] {
  text-align: center;
  font-size: 14px;
  min-width: 42px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

